/**
 * 高德地图的封装
 */

export default {
    //Vue.js插件应当有一个公开方法install,第一个参数书Vue是构造器第二个参数是可选项
    install: function (Vue) {
        //初始化地图
        Vue.prototype.initMap = function () {

            this.map = new AMap.Map('map_container', {
                resizeEnable: true, //是否监控地图容器尺寸变化
                showIndoorMap: false, //隐藏地图自带的室内地图图层
                zooms: [3, 18],
                zoom: 17, //地图缩放等级
                center: [108.94705, 34.259429], //以钟楼为中心点
                features: ['bg', 'road', 'point'], //设置地图上显示的元素种类
                // mapStyle: "amap://styles/42d76f14a0484540ff0b282d457e82f8"
                mapStyle: "amap://styles/normal" /**blue、graffiti、darkblue */
            })
            // 在图面添加比例尺控件，展示地图在当前层级和纬度下的比例尺
            this.map.addControl(new AMap.Scale({

                }

            ));
            var _this = this
            AMapUI.loadUI(['control/BasicControl'], function (BasicControl) {

                var layerCtrl = new BasicControl.LayerSwitcher({
                    //my-red 见上方style
                    theme: 'my-red',
                    position: {
                        bottom: '100px',
                        right: '1%'
                    }
                });

                _this.map.addControl(layerCtrl);


                //缩放控件
                _this.map.addControl(new BasicControl.Zoom({
                    //内置的dark主题
                    theme: 'dark',

                    //左下角
                    position: {
                        bottom: '2%',
                        right: '1%'
                    }
                }));

                //动态加载css
                // AMapUI.loadCss('./zoom-green.css', function () {

                //     var zoomCtrl = new BasicControl.Zoom({
                //         //见zoom-green.css
                //         theme: 'my-green',
                //         position: 'bl',
                //         showZoomNum: false
                //     });

                //     _this.map.addControl(zoomCtrl);
                // });
            });
        }
        //地图各种坐标系之间转换
        Vue.prototype.OutOfChina = function (lng, lat) {
            if (lng < 72.004 || lng > 137.8347)
                return true;
            if (lat < 0.8293 || lat > 55.8271)
                return true;
            return false;
        }

        Vue.prototype.TransformLat = function (x, y) {
            var ret = -100.0 + 2.0 * x + 3.0 * y + 0.2 * y * y + 0.1 * x * y + 0.2 * Math.sqrt(Math.abs(x));
            ret += (20.0 * Math.sin(6.0 * x * Math.PI) + 20.0 * Math.sin(2.0 * x * Math.PI)) * 2.0 / 3.0;
            ret += (20.0 * Math.sin(y * Math.PI) + 40.0 * Math.sin(y / 3.0 * Math.PI)) * 2.0 / 3.0;
            ret += (160.0 * Math.sin(y / 12.0 * Math.PI) + 320 * Math.sin(y * Math.PI / 30.0)) * 2.0 / 3.0;
            return ret;
        }

        Vue.prototype.TransformLng = function (x, y) {
            var ret = 300.0 + x + 2.0 * y + 0.1 * x * x + 0.1 * x * y + 0.1 * Math.sqrt(Math.abs(x));
            ret += (20.0 * Math.sin(6.0 * x * Math.PI) + 20.0 * Math.sin(2.0 * x * Math.PI)) * 2.0 / 3.0;
            ret += (20.0 * Math.sin(x * Math.PI) + 40.0 * Math.sin(x / 3.0 * Math.PI)) * 2.0 / 3.0;
            ret += (150.0 * Math.sin(x / 12.0 * Math.PI) + 300.0 * Math.sin(x / 30.0 * Math.PI)) * 2.0 / 3.0;
            return ret;
        }

        Vue.prototype.Transform = function (wgLng, wgLat) {
            wgLng = parseFloat(wgLng);
            wgLat = parseFloat(wgLat);
            if (this.OutOfChina(wgLng, wgLat)) {
                return {
                    lng: wgLng,
                    lat: wgLat
                };
            }
            var dLat = this.TransformLat(wgLng - 105.0, wgLat - 35.0);
            var dLng = this.TransformLng(wgLng - 105.0, wgLat - 35.0);
            var radLat = wgLat / 180.0 * Math.PI;
            var magic = Math.sin(radLat);
            var a = 6378245.0; //wgs84坐标系的长半轴
            var ee = 0.00669342162296594323; //第二偏心率平方
            magic = 1 - ee * magic * magic;
            var sqrtMagic = Math.sqrt(magic);
            dLat = (dLat * 180.0) / ((a * (1 - ee)) / (magic * sqrtMagic) * Math.PI);
            dLng = (dLng * 180.0) / (a / sqrtMagic * Math.cos(radLat) * Math.PI);

            // return {
            //     lng: wgLng + dLng,
            //     lat: wgLat + dLat
            // };
            return [wgLng + dLng, wgLat + dLat]
        }

        // 从WGS84坐标系到火星坐标系(GCJ-02)的转换
        Vue.prototype.Gps84_To_Gcj02 = function (wgslng, wgslat) {
            return this.Transform(wgslng, wgslat);
        }

        // 从火星坐标系(GCJ-02) 到WGS84坐标系的转换
        Vue.prototype.Gcj02_To_Gps84 = function (gcjLng, gcjLat) {
            var latLng = this.Transform(gcjLng, gcjLat);
            return {
                lng: gcjLng * 2 - latLng.lng,
                lat: gcjLat * 2 - latLng.lat,
            };
        }

        //从火星坐标系 (GCJ-02) 到百度坐标系 (BD-09) 的转换
        Vue.prototype.Gcj02_To_Bd09 = function (gcjLng, gcjLat) {
            var x = parseFloat(gcjLng),
                y = parseFloat(gcjLat);
            var z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * Math.PI);
            var theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * Math.PI);
            return {
                lng: (z * Math.cos(theta) + 0.0065),
                lat: (z * Math.sin(theta) + 0.006)
            };
        }

        //从百度坐标系 (BD-09)  到火星坐标系 (GCJ-02)的转换
        Vue.prototype.Bd09_To_Gcj02 = function (bdLng, bdLat) {
            var x = parseFloat(bdLng) - 0.0065,
                y = parseFloat(bdLat) - 0.006;
            var z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * Math.PI);
            var theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * Math.PI);
            return {
                lng: z * Math.cos(theta),
                lat: z * Math.sin(theta)
            };
        }

        //从百度坐标系 (BD-09)  到WGS84坐标系的转换
        Vue.prototype.Bd09_To_Gps84 = function (bdLng, bdLat) {
            var gcj02 = this.Bd09_To_Gcj02(bd_lat, bd_lon);
            return this.Gcj02_To_Gps84(gcj02.lng, gcj02.lat);
        }

        //从WGS84坐标系  到百度坐标系 (BD-09)的转换
        Vue.prototype.Gps84_To_Bd09 = function (wgsLng, wgsLat) {
            var gcj02 = this.Gps84_To_Gcj02_To_Gcj02(bd_lat, bd_lon);
            return this.Gcj02_To_Bd09(gcj02.lng, gcj02.lat);
        }
        //高德逆地理编码
        // Vue.prototype.getaddress = function (lnglat) {
        //     let that = this;

        //     var geocoder = new AMap.Geocoder({
        //         city: '全国'
        //     })
        //      geocoder.getAddress(lnglat, function (status, result) {
        //         if (status === 'complete' && result.info === 'OK') {
        //             console.log(result.regeocode.formattedAddress)

        //         } else {
        //             that.gaodedress = "未知地址";
        //         }
        //     })   
        // }


        //添加信息窗体(点击需求点图标时显示)
        // Vue.prototype.addInfowindow = function (lnglats) {
        //     let _this = this
        //     this.map.on("complete", function () {
        //         var infoWindow = new AMap.InfoWindow({ autoMove: true,offset: new AMap.Pixel(0, -30) });
        //         //遍历生成多个标记点
        //         for (var i = 0, marker; i < lnglats.length; i++) {
        //             var marker = new AMap.Marker({
        //                 position: lnglats[i], //不同标记点的经纬度
        //                 map: _this.map,
        //             });
        //             marker.content = `<ul class='main' style="width:340px">
        //                     <li><span style='color:blue'>站点名：${i}</span></li>
        //                     <li><span style='color:blue'>经纬度：${i}</span></li>
        //                     <li><span style='color:blue'>启用时间：${i}</span></li>
        //                      <li><button @click="test('222')">点击点击</button></li>
        //                   </ul>`;
        //             marker.on("click", markerclick);
        //             marker.emit("click", { target: marker }); //默认初始化不出现信息窗体,打开初始化就出现信息窗体
        //         }
        //         function markerclick(e) {
        //             infoWindow.setContent(e.target.content);
        //             infoWindow.open(_this.map, e.target.getPosition());
        //             infoWindow.setAnchor("middle-left");
        //         }
        //         _this.map.setFitView();
        //     })

        // }


    }
}