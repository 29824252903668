import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    adminrole: localStorage.getItem('role')||''
  },
  mutations: {
    saveAdminInfo(state, role) {
      state.adminrole = role;
    },
  },
  actions: {},
  modules: {}
})