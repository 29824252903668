import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)
// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }
export const routes = [{
    path: '/',
    name: 'login',
    component: () => import('../views/login.vue')
  },

  {

    path: '/home',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    redirect: '/home/admap',
    children: [{
        path: 'admap',
        name: 'admap',
        component: () => import('../views/admin/admap.vue'),
      }, {
        path: 'adalarmHistory',
        name: 'adalarmHistory',
        component: () => import('../views/admin/adalarmHistory.vue'),
      }, {
        path: 'adequipmentLog',
        name: 'adequipmentLog',
        component: () => import('../views/admin/adequipmentLog.vue'),
      },

      {
        path: 'alcompany',
        name: 'alcompany',
        component: () => import('../views/admin/alcompany.vue'),
      },
      {
        path: 'alequipment',
        name: 'alequipment',
        component: () => import('../views/admin/alequipment.vue'),
      },
      {
        path: 'aldata',
        name: 'aldata',
        component: () => import('../views/admin/aldata.vue'),
      },
      {
        path: 'alsystemLog',
        name: 'alsystemLog',
        component: () => import('../views/admin/alsystemLog.vue'),
      },
      {
        path: 'myeqm',
        name: 'myeqm',
        component: () => import('../views/admin/myeqm.vue'),
      },
      {
        path: 'payment',
        name: 'payment',
        component: () => import('../views/admin/payment.vue'),
      },
       {
         path: 'orderform',
         name: 'orderform',
         component: () => import('../views/admin/orderform.vue'),
       }
    ]
  },
  // {
  //   path: '/',
  //   redirect: '/login'
  // }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router