import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueAMap from 'vue-amap'
import ElementUI from 'element-ui'
import map from './util/map'
import "../src/util/directives"
import 'element-ui/lib/theme-chalk/index.css'
import VueVideoPlayer from 'vue-video-player'
import 'videojs-contrib-hls'
import 'video.js/dist/video-js.css'
import Cookies from 'js-cookie'
import axios from 'axios';

import Plugin from 'v-fit-columns';

Vue.use(Plugin);
// import '../src/util/adaption.js'
const echarts = require('echarts');
Vue.prototype.$echarts = echarts
// require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.use(map)
Vue.use(VueVideoPlayer)
Vue.use(ElementUI)

Vue.use(VueAMap)
VueAMap.initAMapApiLoader({
  key: 'f130b8f9217a257d1a825e072af59bbb',
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor', 'AMap.MarkerClusterer'],
  // 默认高德 sdk 版本为 1.4.4
  v: '1.4.4',
  uiVersion: '1.0.11'
})
Vue.config.productionTip = false
axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded';
Vue.prototype.$cookies = Cookies;
router.beforeEach((to, from, next) => {
  var token = Cookies.get("token")
  if (token && to.path === '/') {
    next({
      path: '/home'
    })
  }
  if (!token && to.path == '/home') {
    Cookies.remove('token');
    Cookies.remove('user');
    Cookies.remove('userRoleId');
    next({
      path: '/'
    })
  }
  if (!token && to.path !== '/') {
    next({
      path: '/'
    })
  } else {
    next()
  }
})

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
}).$mount('#app')